<template>
  <div>
    <PaymentMethods></PaymentMethods>
  </div>
</template>

<script>
import PaymentMethods from "../components/PaymentMethods/index.vue";
export default {
  components: { PaymentMethods },
};
</script>

<style></style>
